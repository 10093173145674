/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Slideshow, Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Produkty značky Meglio"}>
        <Slideshow name={"puck9tz5gv"}>
          
          <Column className="css-1fydyha --full pb--60 pt--60" fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cf96cf2f1fbe4a4ca0a95f3c7db870a1_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cf96cf2f1fbe4a4ca0a95f3c7db870a1_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cf96cf2f1fbe4a4ca0a95f3c7db870a1_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cf96cf2f1fbe4a4ca0a95f3c7db870a1_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cf96cf2f1fbe4a4ca0a95f3c7db870a1_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cf96cf2f1fbe4a4ca0a95f3c7db870a1_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cf96cf2f1fbe4a4ca0a95f3c7db870a1_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/35535/cf96cf2f1fbe4a4ca0a95f3c7db870a1_s=3000x_.png);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper >
                
                <Title className="title-box fs--102 title-box--invert" content={"Meglio&nbsp;"}>
                </Title>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column className="pb--60 pt--60" name={"1rmq9k53mzd"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--16" content={"Meglio\nCitronový odmašťovač 750ml"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":399}} content={"Odstraňte mastné skvrny, obnovte svůj lesk a obklopte se svěžestí citronové vůně! Revoluční koncentrované složení odmašťovače vám umožní odstranit i ty nejodolnější nečistoty na jakémkoli povrchu. Čištění ještě nikdy nebylo tak rychlé a snadné. Prostředek proniká hluboko a odstraňuje nejtěžší nečistoty a zanechává svěží vůni.\n\nPoužití:\nSprej snadno proniká do těžko přístupných zákoutí a dokonale si poradí se zbytkovými mastnými usazeninami pokrývajícími digestoř a troubu. Místa vystavená vysokým teplotám, krby nebo grily budou očištěny od spáleného tuku, který neutralizuje nepříjemné pachy. Je univerzální, takže odstraňuje mastnotu nejen z kovů, ale také z plastových povrchů. S odmašťovačem se zbavíte odolných skvrn z oděvů a obuvi. Bude perfektní nejen doma, umožní vám postarat se o čistotu motorů sekaček na trávu i motocyklů nebo automobilů.\n\nJak používat:\nNastříkejte na znečištěný povrch a počkejte až 4 minuty, než se objeví těžko odstranitelné skvrny, poté opláchněte.\nU digestoří nechte 30 minut působit ve vodném roztoku s 10% rozpuštěným produktem a poté opláchněte."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":325}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/f1bdb88bafa3459da5aa33d84ae4aab0_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/f1bdb88bafa3459da5aa33d84ae4aab0_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":320}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/1600da471cb641b2a00e858b8cf55141_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/1600da471cb641b2a00e858b8cf55141_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/1600da471cb641b2a00e858b8cf55141_s=660x_.png 660w, https://cdn.swbpg.com/t/35535/1600da471cb641b2a00e858b8cf55141_s=860x_.png 860w, https://cdn.swbpg.com/t/35535/1600da471cb641b2a00e858b8cf55141_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--16" content={"Meglio\nOdmašťovač Marsiglia 750ml"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":399}} content={"Marsiglia je produkt obohacený o speciální recepturu založenou na jemných vlastnostech marseillského mýdla. Je ideální pro jemný povrch textilií, odstraní skvrny a zanechá látku bez poskvrny čistou. Revoluční koncentrované složení odmašťovače vám umožní odstranit i ty nejodolnější nečistoty na jakémkoli povrchu. Čištění ještě nikdy nebylo tak rychlé a snadné. Prostředek proniká hluboko a odstraňuje nejtěžší nečistoty a zanechává svěží vůni.\n\nPoužití:\nSprej snadno proniká do těžko přístupných zákoutí a dokonale si poradí se zbytkovými mastnými usazeninami pokrývajícími digestoř a troubu. Místa vystavená vysokým teplotám, krby nebo grily budou očištěny od spáleného tuku, který neutralizuje nepříjemné pachy. Je univerzální, takže odstraňuje mastnotu nejen z kovů, ale také z plastových povrchů. S odmašťovačem se zbavíte odolných skvrn z oděvů a obuvi. Bude perfektní nejen doma, umožní vám postarat se o čistotu motorů sekaček na trávu i motocyklů nebo automobilů.\n\nJak používat:\nNastříkejte na znečištěný povrch a počkejte až 4 minuty, než se objeví těžko odstranitelné skvrny, poté opláchněte. U digestoří nechte 30 minut působit ve vodném roztoku s 10% rozpuštěným produktem a poté opláchněte."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ze76xem16xb"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--16" content={"Meglio\nOdmašťovač + bělidlo 750ml"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":399}} content={"Jedná se o produkt nejvyšší kvality a účinnosti, který navazuje na velký úspěch celé řady odmašťovačů Meglio. Jedná se o produkt, který na polici vyniká díky barvě obalu, grafice a velmi estetickému tvaru lahve s rozprašovačem.\n\nPoužití:\nHloubková čistota, bělící účinek a maximální účinnost na odolné nečistoty v celém domě. Je vynikající v kuchyni pro čištění dřezů, pracovních desek, dlaždic a sporáků. V koupelně je užitečný k čištění toalety a k udržení čisté a lesklé sprchy, díky své účinnosti čistí nečistoty mezi dlaždicemi. Odstraňuje nečistoty z povrchů, kde děti jedí a hrají si. Je vhodný k odstraňování nečistot z límců a manžet bílých košil a skvrn od jiných bílých věcí.\n\nJak používat:\nNastříkejte přípravek ze vzdálenosti asi 20 cm od povrchu a poté otřete vlhkou houbou. V případě odolných nečistot jej nechte několik minut působit. Nepoužívejte na koberce, barevné tkaniny, lakované povrchy a tapety. Kovové povrchy ihned po použití opláchněte. Nepoužívejte na horké povrchy, hliník a dřevo."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":173}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/753846e118b14f6087896b35a6fea11a_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/753846e118b14f6087896b35a6fea11a_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/753846e118b14f6087896b35a6fea11a_s=660x_.png 660w, https://cdn.swbpg.com/t/35535/753846e118b14f6087896b35a6fea11a_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":216}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/d616dc9fd50844c6b39a913769f4b4a1_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/d616dc9fd50844c6b39a913769f4b4a1_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/d616dc9fd50844c6b39a913769f4b4a1_s=660x_.png 660w, https://cdn.swbpg.com/t/35535/d616dc9fd50844c6b39a913769f4b4a1_s=860x_.png 860w, https://cdn.swbpg.com/t/35535/d616dc9fd50844c6b39a913769f4b4a1_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--16" content={"Meglio\nKoupelna 650ml"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":399}} content={"Koupelna je prostředek na čištění koupelen. Snadno odstraňuje každodenní nečistoty, které se usazují na armaturách nebo dlaždicích. Dodává jim lesk a nezanechává šmouhy.\n\nPoužití:\nS ním snadno odstraníte nečistoty, jako je vodní kámen, rez, zbytky mýdla nebo skvrny od vody. Jedinečné složení vám pomůže ochránit vaše povrchy před opětovným usazováním nečistot a udržet je čisté a svěží po ještě delší dobu.\n\nVýrobek je vhodný k čištění: sanitární techniky, sprchových koutů, sprchových vaniček, sprchových koutů, koupelnových armatur, obkladů, spár, skleněných a plastových krytů.\n\nNepoužívejte na mramor a jiné přírodní kameny, měkký nábytek, barevný nebo poškozený smalt a smalt starší než 10 let. Vyvarujte se kontaktu se dřevem, hliníkem, porézními nebo lakovanými povrchy.\n\nJak používat:\nPřípravek stačí nastříkat na čištěný povrch, počkat několik sekund a poté jej smýt vlhkým hadříkem. V případě odolných nečistot opakujte výše uvedenou akci"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"nucqt33dq2p"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--16" content={"Meglio\nProti vodnímu kameni 650ml"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":399}} content={"Nejen, že účinně odstraňuje vodní kámen z vaší koupelny, ale také účinně odstraňuje nečistoty, zbytky mýdla a dodává povrchům lesk.\n\nPoužití:\n\nIdeální pro čištění chromových povrchů, nerezové oceli, keramiky, porcelánu, van, sprch, umyvadel, vodovodních baterií a toalet.\n\nNepoužívejte na vápencové povrchy (mramor, travertin), měď, hliník, poškrábané nebo poškozené povrchy a domácí spotřebiče.\n\nPřed použitím se doporučuje produkt otestovat na malé neviditelné ploše. Pokud povrch neztratí svůj lesk, lze produkt používat bez rizika.\n\nJak používat:\n\nNastříkejte přípravek přímo na čištěný povrch, nechte několik minut působit a důkladně opláchněte. Pro větší lesk osušte měkkým a čistým hadříkem. Zbavte se vodního kamene a nechte celou koupelnu lesklou!"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":221}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/0b53427436fc46788fee368891065af5_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/0b53427436fc46788fee368891065af5_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/0b53427436fc46788fee368891065af5_s=660x_.png 660w, https://cdn.swbpg.com/t/35535/0b53427436fc46788fee368891065af5_s=860x_.png 860w, https://cdn.swbpg.com/t/35535/0b53427436fc46788fee368891065af5_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":299}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/8477396c77c84d4c92080b53250f1906_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/8477396c77c84d4c92080b53250f1906_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/8477396c77c84d4c92080b53250f1906_s=660x_.png 660w, https://cdn.swbpg.com/t/35535/8477396c77c84d4c92080b53250f1906_s=860x_.png 860w, https://cdn.swbpg.com/t/35535/8477396c77c84d4c92080b53250f1906_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--16" content={"Meglio\nCitronový odmašťovač náplň 750ml"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":399}} content={"Odstraňte mastné skvrny, obnovte svůj lesk a obklopte se svěžestí citronové vůně! Revoluční koncentrované složení odmašťovače vám umožní odstranit i ty nejodolnější nečistoty na jakémkoli povrchu. Čištění ještě nikdy nebylo tak rychlé a snadné. Prostředek proniká hluboko a odstraňuje nejtěžší nečistoty a zanechává svěží vůni. Použití: Sprej snadno proniká do těžko přístupných zákoutí a dokonale si poradí se zbytkovými mastnými usazeninami pokrývajícími digestoř a troubu. Místa vystavená vysokým teplotám, krby nebo grily budou očištěny od spáleného tuku, který neutralizuje nepříjemné pachy. Je univerzální, takže odstraňuje mastnotu nejen z kovů, ale také z plastových povrchů. S odmašťovačem se zbavíte odolných skvrn z oděvů a obuvi. Bude perfektní nejen doma, umožní vám postarat se o čistotu motorů sekaček na trávu i motocyklů nebo automobilů. Jak používat: Nastříkejte na znečištěný povrch a počkejte až 4 minuty, než se objeví těžko odstranitelné skvrny, poté opláchněte. U digestoří nechte 30 minut působit ve vodném roztoku s 10% rozpuštěným produktem a poté opláchněte."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"tmz8gpu193"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}